import {
  buildHTTPGetOptions,
  buildHTTPPostOptions,
  checkResponse,
  checkResponseHeader,
  checkTextResponse,
  downloadFile,
  downloadFileAnyType,
  fetchBuilder,
  onlyFetchBuilder,
  processError,
} from '../../utils/fetch-utils'
import { Action } from './actions'
import { Dispatch } from 'redux'
import { throwErrorMessage, throwSuccessMessage, throwWarnMessage } from '../../utils/errors-utils'

export const onlyFetchReportRules = onlyFetchBuilder('/api/reportGenerator/GetRules', {
  Platform: true,
  Page: true,
  Count: true,
})
export const fetchReportRules = fetchBuilder(onlyFetchReportRules, Action.GotReportRules)

export function fetchReportDelete(params: any) {
  return (dispatch: Dispatch) => {
    const url = new URL('/api/reportGenerator/DeleteRule', window.location.origin)
    url.searchParams.set('gateway', params.Gateway)
    url.searchParams.set('Platform', params.Platform)
    url.searchParams.set('ruleName', params.ScheduleRuleName)
    url.searchParams.set('deleteFiles', params.Checked)

    dispatch({ type: Action.InProgressEnd })
    params.setLoading(true)

    return fetch(url.toString(), buildHTTPGetOptions(params.signal))
      .then((response: Response) => checkResponse(response))
      .then((result: any) => {
        if (result.Status) {
          return new Promise((resolve, reject) => reject(new Error('error.404')))
        } else {
          throwSuccessMessage('Successfully')
          dispatch({ type: Action.DeleteReportRule, data: { ScheduleRuleName: params.ScheduleRuleName } })
        }
      })
      .then((data: any) => dispatch({ type: Action.GotConsolidationRules, data }))
      .catch((error: Error) => processError(error, dispatch))
      .finally(() => {
        dispatch({ type: Action.InProgressEnd })
        params.setLoading(false)
      })
  }
}

export function downloadReportFile(params: any) {
  return (dispatch: Dispatch) => {
    const url = new URL('/api/reportGenerator/DownloadReportFile', window.location.origin)
    url.searchParams.set('gateway', params.Gateway)
    url.searchParams.set('Platform', params.Platform)
    url.searchParams.set('ruleName', params.ScheduleRuleName)
    url.searchParams.set('fileName', params.fileName)

    return fetch(url.toString(), buildHTTPGetOptions())
      .then(res => {
        return {
          filename: checkResponseHeader(res),
          res,
        }
      })
      .then(async (data: any) => {
        const body: any = await checkTextResponse(data.res)
        downloadFileAnyType(`${data.filename.split('"').filter(Boolean).join('')}`, body, body.type)
        throwSuccessMessage('Successfully')
      })
      .catch((error: Error) => processError(error, dispatch))
  }
}

export function downloadReportAllFile(params: any) {
  return (dispatch: Dispatch) => {
    const url = new URL('/api/reportGenerator/DownloadAllReportFiles', window.location.origin)
    url.searchParams.set('gateway', params.Gateway)
    url.searchParams.set('Platform', params.Platform)
    url.searchParams.set('ruleName', params.ScheduleRuleName)

    return fetch(url.toString(), buildHTTPGetOptions())
      .then((response: Response) => checkTextResponse(response))
      .then((data: any) => {
        downloadFile(`${params.ScheduleRuleName}-all-reports.zip`, data)
        throwSuccessMessage('Successfully')
      })
      .catch((error: Error) => processError(error, dispatch))
  }
}

export function downloadRegenerateReportFile(params: any) {
  return (dispatch: Dispatch) => {
    const url = new URL('/api/reportGenerator/RegenerateReport', window.location.origin)
    url.searchParams.set('gateway', params.Gateway)
    url.searchParams.set('Platform', params.Platform)
    url.searchParams.set('ruleName', params.ScheduleRuleName)
    url.searchParams.set('fileName', params.fileName)

    return fetch(url.toString(), buildHTTPGetOptions())
      .then((response: Response) => {
        const filename: any = response.headers

        if (response.status === 500) {
          return checkResponse(response)
        } else {
          return { res: response, name: filename.get('content-disposition').split('filename=')[1].split(';')[0].split('"').filter(Boolean).join('') }
        }
      })
      .then(async (data: any) => {
        if (data.name) {
          const body: any = await checkTextResponse(data.res)
          downloadFile(`${data.name}`, body)
          throwSuccessMessage('Successfully')
        } else {
          return checkResponse(data.res)
        }
      })
      .then((res: any) => {
        if (res.Status === 2) {
          throwWarnMessage(res.Description)
        } else {
          throwErrorMessage(res.Description, true)
        }
      })
      .catch((error: Error) => processError(error, dispatch))
  }
}
export function fetchRegenerateReport(params: any) {
  return (dispatch: Dispatch) => {
    const url = new URL('/api/reportGenerator/RegenerateReport', window.location.origin)
    url.searchParams.set('gateway', params.Gateway)
    url.searchParams.set('Platform', params.Platform)
    url.searchParams.set('from', params.from)
    url.searchParams.set('to', params.to)

    dispatch({ type: Action.InProgressEnd })
    params.setLoading(true)

    return fetch(url.toString(), buildHTTPPostOptions({ ...params.body }))
      .then((res: any) => {
        return {
          filename: checkResponseHeader(res),
          res,
        }
      })
      .then(async (data: any) => {
        if (data.filename) {
          const body: any = await checkTextResponse(data.res)
          downloadFileAnyType(`${data.filename.split('"').filter(Boolean).join('')}`, body, body.type)
          throwSuccessMessage('Successfully')
        } else {
          return checkResponse(data.res)
        }
      })
      .then((res: any) => {
        if (res.Status === 2) {
          throwWarnMessage(res.Description)
        } else {
          throwErrorMessage(res.Description, true)
        }
      })
      .catch((error: Error) => processError(error, dispatch))
      .finally(() => {
        dispatch({ type: Action.InProgressEnd })
        params.setLoading(false)
      })
  }
}

export function fetchAddReport(params: any) {
  return (dispatch: Dispatch) => {
    const url = new URL('/api/reportGenerator/SetRule', window.location.origin)

    url.searchParams.set('gateway', params.Gateway)
    url.searchParams.set('Platform', params.Platform)
    url.searchParams.set('ruleName', params.ruleName)

    dispatch({ type: Action.InProgressEnd })
    params.setLoading(true)

    return fetch(url.toString(), buildHTTPPostOptions({ ...params.body }, '/api/reportGenerator/SetRule'))
      .then((response: Response) => checkResponse(response, '/api/reportGenerator/GetRules'))
      .then((result: any) => {
        if (result.Status) {
          throwErrorMessage(result.Description, true)
        } else {
          throwSuccessMessage('Successfully')
        }
      })
      .then((data: any) => dispatch({ type: Action.GotConsolidationRules, data }))
      .catch((error: Error) => processError(error, dispatch))
      .finally(() => {
        dispatch({ type: Action.InProgressEnd })
        params.setLoading(false)
      })
  }
}
